<template>
  <div>
    <PaymentTitle type="Edit" />

    <VLoader v-if="_fetchingStatusDetails === requestStatus.PENDING || _fetchingStatusList === requestStatus.PENDING" />
    <HandlePaymentDetails
      v-else-if="_fetchingStatusDetails === requestStatus.SUCCESS && _fetchingStatusList === requestStatus.SUCCESS"
      :payment-method-id="paymentMethodID"
      :payment-details="_paymentMethodDetails"
    />
  </div>
</template>

<script>
import HandlePaymentDetails from '@/js/components/pages/payments/HandlePaymentDetails';
import PaymentTitle from '@/js/components/pages/payments/HandlePaymentDetails/PaymentTitle';
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "EditPaymentMethod",
  components: {
    PaymentTitle,
    HandlePaymentDetails
  },
  data() {
    return {
      requestStatus,
      paymentMethodID: +this.$route.params.id || null,
    };
  },
  computed: {
    _fetchingStatusList() {
      return this.$store.state.paymentMethods.fetchingStatus;
    },
    _paymentMethodsList() {
      return this.$store.state.paymentMethods.list;
    },
    _fetchingStatusDetails() {
      return this.$store.state.paymentMethodsDetails.fetchingStatus;
    },
    _paymentMethodDetails() {
      return this.$store.state.paymentMethodsDetails.details;
    },
  },
  mounted() {
    if (!this._paymentMethodsList.length) this.getPaymentMethods();
    if (this.paymentMethodID) this.getPaymentMethodDetails();
  },
  methods: {
    async getPaymentMethods() {
      await this.$store.dispatch('paymentMethods/getPaymentMethods');
    },
    async getPaymentMethodDetails() {
      this.$store.commit('paymentMethodsDetails/SET_PAYMENT_METHOD_ID', this.paymentMethodID);
      await this.$store.dispatch('paymentMethodsDetails/getPaymentMethodDetails', this.paymentMethodID);
    },
  }
};
</script>

<style lang="scss" scoped>

</style>